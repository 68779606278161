import React from 'react';
import { graphql } from 'gatsby';
import Container from 'components/Container';
import Block from 'components/Block';
import PageHeader from 'components/PageHeader';
import Breadcrumbs, { Props as BreadcrumbsProps } from 'src/components/Breadcrumbs';
import Seo from 'src/lib/components/Seo';
import CtaBlock from 'components/CtaBlock';
import IndustryServiceQuery_IndustryService from 'src/transformers/IndustryServiceQuery_IndustryService';
import SubNavMenu, { Props as SubNavMenuProps } from 'src/components/SubNavMenu';
import ContentParser from 'src/components/ContentParser';
import { resolveUrl } from 'helpers';

interface Props {
  data: GatsbyTypes.SingleIndustryServiceWithSiblingsQuery,
  // pageContext: { serviceId: string },
}

export const query = graphql`
  query SingleIndustryServiceWithSiblings(
    $industryServiceId: String,
    $siblingIndustryServiceIds: [String],
  ) {
    wpIndustryService(id: { eq: $industryServiceId }) {
      ...IndustryServiceWithLinkedParents
      acfSeo {
        metaDescription
      }
    }
    siblingIndustryServices: allWpIndustryService(
      filter: {id: {in: $siblingIndustryServiceIds}},
      sort: {order: ASC, fields: menuOrder},
    ) {
      nodes {
        ...IndustryServiceWithLinkedParents
      }
    }
  }
`;


const IndustryServicePage: React.FC<Props> = ({ data }) => {
  const industryServiceQuery = data.wpIndustryService;
  const industryService = IndustryServiceQuery_IndustryService(industryServiceQuery);
  if (industryService === null) return null;

  const breadcrumbs: BreadcrumbsProps['items'] = [
    { label: 'Industries', url: resolveUrl.industry('') },
    {
      label: industryService.linkedIndustry?.navTitle || '',
      url: resolveUrl.industry(industryService.linkedIndustry?.slug || ''),
    },
    { label: industryService.getTitle() },
  ];

  const subNavMenuItems: SubNavMenuProps['items'] = (
    data.siblingIndustryServices.nodes.reduce<SubNavMenuProps['items']>((acc, itemRaw) => {
      const item = IndustryServiceQuery_IndustryService(itemRaw);
      if (item) {
        acc.push({
          label: item.getTitle(),
          active: item.slug === industryService.slug,
          url: resolveUrl.industryService(
            item.linkedIndustry?.slug || '',
            item.linkedService?.slug || '',
          ),
        });
      }
      return acc;
    }, [])
  );

  return (
    <>
      <Seo
        title={industryService.metaTitle}
        // titlePath={['Industries', industryService.linkedIndustry?.name]}
        description={industryService.metaDescription}
      />
      <PageHeader
        category="Industry Tailored Services"
        h1={industryService.h1}
        backgroundText={industryService.contentHtml}
      />
      {subNavMenuItems.length > 0 && (
        <Block darkBg type="noPadding">
          <SubNavMenu items={subNavMenuItems} />
        </Block>
      )}
      <Container>
        <Breadcrumbs items={breadcrumbs} />
        {industryService.contentHtml && (
          <Block readableWidth contain containerConfig={{ yPadding: 0, xPadding: 0 }}>
            <ContentParser>{industryService.contentHtml}</ContentParser>
          </Block>
        )}
      </Container>

      <CtaBlock />
    </>
  );
};

export default IndustryServicePage;
